import React from "react"
import { MDXProvider } from "@mdx-js/react"
// import { Logo } from './src/components/Logo'

// components is its own object outside of render so that the references to
// components are stable
// const components = { Logo }
const components = {}
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
)
